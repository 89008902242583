<template>
    <div id="home-page">
        <div class="page-header">
            <div class="header-bar">
                <el-button type="primary" class="login-btn" @click="directToLogin">登录/注册</el-button>
                <el-button link>副业赚钱</el-button>
                <el-button link>公益资料</el-button>
            </div>
            <div class="logo"></div>
            <h2>流云AI产品及服务</h2>
        </div>
        <div class="page-body">
            <div class="button-list">
                <div 
                    :class="item.disable==false?'nav-button':'nav-button-disable'" 
                    v-for="(item,index) in buttonList1" 
                    :key="index"
                    @click="clickNavButton(item)">
                    <div class="btn-icon">
                        <!-- <img :src="item.icon"/> -->
                        <el-icon class="icon"><Document /></el-icon>
                    </div>
                    <div class="btn-content">
                        <h3 class="btn-title">{{ item.title }}</h3>
                        <!-- <div class="btn-subtitle">{{ item.content }}</div> -->
                    </div>
                </div>
            </div>
            <!-- <el-button 
                class="nav-button" 
                v-for="(item,index) in buttonList2" 
                :key="index">

            </el-button> -->
        </div>
        <div class="page-footer">
            <div class="footer-box1">
                <div class="section-box">
                    <div class="section-title">关于我们</div>
                    <div class="section-content">
                        流云A成立于2023年3月初，专注人
                        工智能应用服务，助力个人及中小企
                        业A数智化服务
                    </div>
                </div>
                <div class="section-box">
                    <div class="section-title">关注我们</div>
                    <div class="section-content">
                        微信公众号: 流云A研究所
                        知识星球: 流云A 研究所
                    </div>
                </div>
                <div class="section-box">
                    <div class="section-title">加入我们</div>
                    <div class="section-content">
                        欢迎有NLP和CV经验的开发考、
                        有数字营销经验和运营的人才加入
                        合作微信plan trip
                    </div>
                </div>
            </div>
            <div class="footer-box2">
                <div class="info-box">
                    <el-button class="footer-btn" type="primary" link>使用条款</el-button>
                    <div class="split-line"></div>
                    <el-button class="footer-btn" type="primary" link>用户协议</el-button>
                </div>
                <div class="copyright">
                    @ 2023 流云AI All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from "vue";
import { Document } from "@element-plus/icons-vue"
import { useRouter } from "vue-router";

const buttonList1 = reactive([{
    icon: '',
    title: '流云AI聊天机器人',
    content: '基于ChatGPT的通用聊天界面',
    url: 'https://liuyuncopilot.com/gpt/zh',
    disable: false
},{
    icon: '',
    title: 'AI画图',
    content: '基于ChatGPT的客制化聊天界面',
    url: 'http://61.85.6.22:7860',
    disable: false
},{
    icon: '',
    title: 'Prompt训练',
    content: '基于Midjourney的AI绘图界面'
},{
    icon: '',
    title: '机器人部署',
    content: '基于Stable Diffusion的AI绘图界面',
    url: 'https://liuyuncopilot.com/manageUI',
    disable: false
},{
    icon: '',
    title: 'GPT网站定制',
    content: '基于ChatPDF的文本处理界面'
},{
    icon: '',
    title: 'API及账号服务',
    content: '基于OpenAI的模型训练界面'
},{
    icon: '',
    title: '知识库私有化部署',
    content: '基于OpenAI的模型训练界面'
},{
    icon: '',
    title: '行业模型及微调服务',
    content: '基于OpenAI的模型训练界面'
},{
    icon: '',
    title: 'AI插件',
    content: '基于OpenAI的模型训练界面'
},])

const clickNavButton = (data) => {
    if('url' in data){
        window.location.href = data.url
    }
}

const router = useRouter()
const directToLogin = () => {
    router.push('/Login')
}
// const buttonList2 = reactive([1,2,3])
</script>

<style scoped>
#home-page{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-header{
    /* height: 30%; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-bar{
    width: 100vw;
    display: flex;
    margin-top: 16px;
    flex-shrink: 1;
    flex-direction: row-reverse;
}
.login-btn{
    margin: 0 16px;
    background: #19c37d;
    border: #19c37d;
}
.logo{
    background-image: url('/logo.png');
    width: 10vw;
    height: 10vw;
    background-size: 100%;
}
.page-body{
    flex-shrink: 1;
    flex: 1;
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow: auto;
}
.button-list{
    height: 300px;
    width: 1000px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
}
.nav-button{
    width: 300px;
    height: 100px;
    margin: 16px 0;
    border: solid 1px #3ff3a9;
    border-radius: 10px;
    box-sizing: border-box;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    cursor: pointer;
}
.nav-button-disable{
    width: 300px;
    height: 100px;
    margin: 16px 0;
    border: solid 1px #c6cdc0;
    border-radius: 10px;
    box-sizing: border-box;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    cursor: pointer;
    background: #c6cdc0;
    color: #a5a0a0;
}
.nav-button:hover{
    background: rgba(188,239,218,0.6);
}
.btn-icon{
    width: 30%;
    height: 50px;
    font-size: 45px;
    margin-right: 8px;
    text-align: center;
    line-height: 50px;
    display: inline;
}
.icon{
    font-size: 24px;
    color: gray;
}
.btn-title{
    width: 70%;
    margin: 8px 0px;
    text-align: left;
    display: inline;
}
.btn-subtitle{
    color: gray;
}
.page-footer{
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 1;
    margin-top: 16px;
}
.footer-box1{
    display: flex;
    width: 60%;
    justify-content: space-around;
}
.section-box{
    width: 20%;
    font-size: 12px;
}
.section-title{
    font-weight: 600;
}
.section-content{

}
.footer-box2{

}
.info-box{
    display: flex;
    justify-content: center;
}
.footer-btn{
    font-size: 12px;
    color: #19c37d;
}
.split-line{
    height: 16px;
    width: 1px;
    background: black;
}
.copyright{
    font-size: 12px;
    margin-bottom: 16px;
}
</style>